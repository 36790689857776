<template>
    <v-container class="pt-10">
        <v-row justify="center">
            <v-col xl="9">
                <full-screen-loader v-if="fetchingUserInfo || fetchingOrgs" />
                <span v-else-if="userInfo && !currentOrgData">
                    Ooops! It seems you have not accepted your invitation yet. Please check your emails and follow the link to accept. If you encounter issues,
                    you can
                    <a @click="showIntercomChat">ask for help</a>
                </span>
                <v-alert
                    v-else-if="!userInfo && (!loginMethod || (loginMethod && loginMethod[0] !== 'password'))"
                    outlined
                    type="warning"
                    prominent
                    border="left">
                    <div style="max-width: 1000px" class="d-flex flex-column">
                        <span class="headline font-weight-bold mb-3">No invitation / Wrong login method</span>
                        <span class="py-3 title">An error has occurred during login. This might be caused by any of the following issues:</span>
                        <ul class="subtitle-2 font-weight-medium">
                            <li>
                                You don't have a valid invitation from a faculty member to use Nuvolos. Please arrange to receive an invitation e-mail and
                                follow the necessary steps to create an account.
                            </li>
                            <li>You have logged in with the wrong method. Make sure you login with the same method you used to signup to Nuvolos.</li>
                        </ul>
                        <v-divider class="my-4 error" style="opacity: 0.22"></v-divider>

                        <v-row align="center" no-gutters>
                            <v-col class="grow">Thank you for your understanding!</v-col>
                            <div class="flex-grow-1"></div>
                            <v-col class="shrink">
                                <v-btn @click="logout" color="error" outlined>logout</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-alert>
                <v-alert v-else-if="!userInfo && loginMethod && loginMethod[0] === 'password'" outlined type="warning" prominent border="left">
                    <div class="d-flex flex-column">
                        <span class="headline font-weight-bold mb-3">Wrong login method</span>
                        <span class="py-3 subtiitle-1">
                            We have detected that you have signed up with your email and password. Please log out and use your email
                            <span class="font-weight-bold">{{ $auth.user.email }}</span>
                            and your password to sign in.
                        </span>
                        <v-divider class="my-4 error" style="opacity: 0.22"></v-divider>

                        <v-row align="center" no-gutters>
                            <v-col class="grow">Thank you for your understanding!</v-col>
                            <div class="flex-grow-1"></div>
                            <v-col class="shrink">
                                <v-btn @click="logout" color="error" outlined>logout</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapState } from 'vuex'
import { intercomMethods } from '@/mixins/intercom'
const FullScreenLoader = () => import('@/views/TheFullScreenLoader')

export default {
    mixins: [intercomMethods],
    data() {
        return {
            loginMethod: null
        }
    },
    computed: {
        ...mapState(['currentOrgData', 'fetchingOrgs', 'userInfo', 'fetchingUserInfo'])
    },
    watch: {
        currentOrgData: {
            handler(value) {
                if (value) {
                    this.$router.push({
                        name: 'home-dashboard',
                        params: { oid: value.oid }
                    })
                }
            },
            immediate: true
        }
    },
    created() {
        this.$axios
            .post('/users/login_methods', { email: this.$auth.user.email })
            .then(response => {
                if (response.data && response.data.methods && response.data.methods.length) {
                    this.loginMethod = response.data.methods
                } else {
                    this.loginMethod = null
                }
            })
            .catch(() => {
                this.loginMethod = null
            })
    },
    methods: {
        logout() {
            this.$auth.logout()
        }
    },
    components: {
        FullScreenLoader
    }
}
</script>
